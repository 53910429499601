import React from "react";
import {FormInputSelect} from "./input/select";
import {FormInputDate} from "./input/date";
import {FormInputText} from "./input/text";
import {FormInputNumber} from "./input/number";

export function FormInput(props: {
    name: string,
    type?: string,
    options?: any,
    readOnly?: boolean,

    required?: null | boolean,
    validator?: any,
    blocker?: null | string,
    blockerMode?: null | number,
    runValidator?: boolean,
    attributes?: any,
} = {
    name: '',
    type: 'text',
    options: null,
    readOnly: false,

    required: null,
    validator: null,
    blocker: null,
    blockerMode: null,
    runValidator: false,

    attributes: null,
}) {
    if (props.type == 'date') {
        return (<FormInputDate
            name={props.name}

            readOnly={props.readOnly}
            required={props.required}
            validator={props.validator}
            blocker={props.blocker}

            runValidator={props.runValidator}
            attributes={props.attributes}
        />)
    }

    if (props.type == 'select') {
        if (props.options == null) {
            props.options = [];
        }

        return (<FormInputSelect
            name={props.name}
            options={props.options}

            readOnly={props.readOnly}
            required={props.required}
            validator={props.validator}
            blocker={props.blocker}

            runValidator={props.runValidator}
            attributes={props.attributes}
        />)
    }

    if (props.type == 'number') {
        return (<FormInputNumber
            name={props.name}
            readOnly={props.readOnly}

            required={props.required}
            validator={props.validator}
            blocker={props.blocker}

            runValidator={props.runValidator}
            attributes={props.attributes}
        />);
    }

    return (<FormInputText
        name={props.name}

        readOnly={props.readOnly}
        required={props.required}
        validator={props.validator}
        blocker={props.blocker}

        runValidator={props.runValidator}
        attributes={props.attributes}
    />);
}
