import React from 'react';
import FormItem from "../form/form_item";
import {useGlobalState} from "../../App";
import {mudurlukler} from "../../settings";

function FirstStep() {
    const [form_data] = useGlobalState('form');

    return (
        <>
            <p>Uygunluk talebini gönderen tapu müdürlüğü ve talebin EBYS tarih ve sayısı</p>
            <FormItem type="select" label={"Tapu Müdürlüğü"} humanRef={"A10MD"} name="mudurluk" blocker={'tarih'} options={mudurlukler} readOnly={!!form_data['_id']}/>
            <FormItem type="date" label={"Tarih"} humanRef={"A10TR"} name="tarih" blocker={'sayi'} readOnly={!!form_data['_id']}/>
            <FormItem type="text" label={"Sayı"} humanRef={"A10SY"} name="sayi" blocker={'taahhut-tarih'} helpContent={"Evrak No"} readOnly={!!form_data['_id']}/>
        </>
    );
}

export default FirstStep;
