import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
import {useGlobalState} from "../../../App";
import {changeFormValue, getFormAttribute, loadInput} from "../../../utils/form";
import {Col, Row} from "react-bootstrap";
import {FormCheckType} from "react-bootstrap/FormCheck";

export function FormInputCheck(props: {
    name: string,
    options: any[],
    type?: FormCheckType,

    readOnly?: boolean,
    required?: null | boolean,
    validator?: any,
    blocker?: null | string,
    blockerMode?: null | number,
    runValidator?: boolean,

    attributes?: any,
} = {
    name: '',
    options: [],
    type: 'radio',

    readOnly: false,
    required: null,
    validator: null,
    blocker: null,
    blockerMode: null,
    runValidator: false,

    attributes: null
}) {
    const [form, setForm] = useGlobalState('form');

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value: string | number = 0;
        if (e.target.checked) {
            value = e.target.value;
        }

        changeFormValue(props.name, value, form, setForm);
    };

    useEffect(() => {
        loadInput(form, props, setForm)
    }, []);

    const field = getFormAttribute(form, props.name);

    return (<Row>
        <Col>
            {props.options.map(function (object: any) {
                return <Form.Check
                    type={props.type}
                    value={object.key}

                    onChange={onChangeHandler}
                    isInvalid={field['isInvalid']}
                    checked={object.key == field['value']}

                    {...props.attributes}

                    name={props.name}
                    label={object.value}
                    id={props.name + '_' + object.key}
                    key={props.name + '_' + object.key}

                    disabled={field['isBlocked']}
                />
            })}
        </Col>
    </Row>)
}
