import React from "react";
import FormItem from "../form/form_item";
import {getFormAttribute} from "../../utils/form";
import {useGlobalState} from "../../App";

function ValuationReport(props: {
    process_id: number,
    real_estate_id: number,
} = {
    process_id: 0,
    real_estate_id: 0
}) {

    const [form_data] = useGlobalState('form');
    const fieldPrefix = 'islem' + props.process_id + '_t' + props.real_estate_id + '_deg-'

    return (
        <>
            <h3>{props.process_id}. İşlem {'>'} {props.real_estate_id}. Taşınmaz Değerleme Raporu</h3>

            <FormItem type="date" label={"Tarih"} humanRef={"TD0TR"} name={fieldPrefix + 'tarih'} blocker={fieldPrefix + 'degerTL'}/>
            <FormItem type="number" attributes={{step: ".01"}} label={"Mevcut kullanıma esas piyasa değeri TL"} humanRef={"TDPDT"} name={fieldPrefix + 'degerTL'} blocker={fieldPrefix + 'degerUSD'}/>
            <FormItem type="number" attributes={{step: ".01"}} label={"Mevcut kullanıma esas piyasa değeri USD"} humanRef={"TDPDU"} name={fieldPrefix + 'degerUSD'}/>

            <p>
                <strong>Not:</strong> Natamam yapılarda binanın tamamlanması durumundaki değer yazılacaktır.<br/>
                Raporlar arası değer farklılığı durumlarında ortalaması alınan değerler girilecektir.
            </p>

            <FormItem type="select" humanRef={"TD0DR"} name={fieldPrefix + 'durum'} blocker={fieldPrefix + 'k1'} options={[
                {key: 1, value: "Değerleme raporuna konu edilen taşınmaz tamamlanmış olup girilen değer mevcut kullanıma esas piyasa değeridir."},
                {key: 2, value: "Değerleme raporuna konu edilen taşınmaz natamam olup girilen değer binanın tamamlanması durumundaki değeridir."},
                {key: 3, value: "Diğer (Diğer seçilirse görüşü yazacağı açıklama alanı açılacak.)"},
            ]} required={false}/>

            {getFormAttribute(form_data, fieldPrefix + 'durum')['value'] == 3 && <FormItem type="text" humanRef={"TD0AC"} name={fieldPrefix + 'aciklama'} required={false}/>}

            <FormItem type="radio" humanRef={"TD0K1"} name={fieldPrefix + 'k1'} blocker={fieldPrefix + 'k2'} blockerMode={2} options={[
                {key: 1, value: "Değerleme raporuna konu edilen taşınmaz ile uygunluğa konu edilen taşınmaz aynı."},
                {key: 2, value: "Değerleme raporuna konu edilen taşınmaz ile uygunluğa konu edilen taşınmaz aynı değil."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K2"} name={fieldPrefix + 'k2'} blocker={fieldPrefix + 'k3'} options={[
                {key: 1, value: "Sicilin incelenmesi neticesinde değerleme raporunun değerini etkileyen (cins değişikliği, yola terk, irtifak hakkı tesisi vb.) unsur bulunmaktadır."},
                {key: 2, value: "Sicilin incelenmesi neticesinde değerleme raporunun değerini etkileyen (cins değişikliği, yola terk, irtifak hakkı tesisi vb.) unsur bulunmamaktadır."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K3"} name={fieldPrefix + 'k3'} blocker={fieldPrefix + 'k4'} options={[
                {key: 1, value: "Değerleme raporu üzerinde eksiklik/maddi hata bulunmamaktadır."},
                {key: 2, value: "Değerleme raporu üzerinde eksiklik/maddi hata bulunmaktadır."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K4"} name={fieldPrefix + 'k4'} blocker={fieldPrefix + 'k5'} options={[
                {key: 1, value: "İlgilisince değere ilişkin itiraz bulunmamaktadır."},
                {key: 2, value: "İlgilisince değere ilişkin itiraz bulunmaktadır."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K5"} name={fieldPrefix + 'k5'} blocker={fieldPrefix + 'k6'} options={[
                {key: 1, value: "Değerleme raporundaki fotoğraflar uygundur."},
                {key: 2, value: "Değerleme raporundaki fotoğraflar uygun değildir."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K6"} name={fieldPrefix + 'k6'} blocker={fieldPrefix + 'k7'} options={[
                {key: 1, value: "Değerleme raporunda kullanılan değerleme yaklaşımları belirtilmiştir."},
                {key: 2, value: "Değerleme raporunda kullanılan değerleme yaklaşımları belirtilmemiştir."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K7"} name={fieldPrefix + 'k7'} blocker={fieldPrefix + 'k8'} options={[
                {key: 1, value: "Değer taktirine ilişkin hesaplar ve uygunlaştırma sunulmuştur."},
                {key: 2, value: "Değer taktirine ilişkin hesaplar ve uygunlaştırma sunulmamıştır."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K8"} name={fieldPrefix + 'k8'} blocker={fieldPrefix + 'k9'} options={[
                {key: 1, value: "Değerlemeye konu taşınmazın tapu sicil bilgileri ile emsallerin niteliklerinin özellikleri incelenmiş olup benzer özelliğe sahiptir."},
                {key: 2, value: "Değerlemeye konu taşınmazın tapu sicil bilgileri ile emsallerin niteliklerinin özellikleri incelenmiş olup benzer özelliğe sahip değildir."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K9"} name={fieldPrefix + 'k9'} blocker={fieldPrefix + 'k10'} options={[
                {key: 1, value: "2021/6 Sayılı genelgenin 4/d maddesi kapsamında gerekli inceleme yapılmış olup değer verisi uygundur."},
                {key: 2, value: "2021/6 Sayılı genelgenin 4/d maddesi kapsamında gerekli inceleme yapılmış olup değer verisi uygun değildir."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }}/>

            <FormItem type="radio" humanRef={"TD0K10"} name={fieldPrefix + 'k10'} options={[
                {key: 1, value: "2021/6 Sayılı genelgenin 4/e/f maddesi kapsamında gerekli inceleme yapılmış olup değer verisi uygundur."},
                {key: 2, value: "2021/6 Sayılı genelgenin 4/e/f maddesi kapsamında gerekli inceleme yapılmış olup değer verisi uygun değildir."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1
            }} runValidator={true}/>
        </>
    );
}

export default ValuationReport;
