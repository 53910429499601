import React from "react";
import {Col, Row} from "react-bootstrap";
import {FormHelp} from "./form_help";
import {FormInput} from "./form_input";
import {FormInputCheck} from "./input/check";

function FormItem(props: {
    label?: string,
    name: string,
    type?: string,
    helpContent?: string | null,
    options?: any,
    readOnly?: boolean,
    humanRef?: null | string,

    required?: null | boolean,
    validator?: any,
    blocker?: null | string,
    blockerMode?: null | number,
    runValidator?: boolean,
    attributes?: any,
} = {
    label: '',
    name: '',
    type: 'text',
    helpContent: 'aa',
    options: null,
    readOnly: false,
    humanRef: null,

    required: null,
    validator: null,
    blocker: null,
    blockerMode: null,
    runValidator: false,
    attributes: null,
}) {
    const isRequired = props.required != null ? props.required : true;

    const printHumanRef = () => {
        return <span style={{fontWeight: "bold", fontSize: "11px", color: '#b3b551'}}>[{props.humanRef}]</span>
    }

    if (props.type == 'radio' || props.type == 'checkbox' || props.type == 'switch') {
        return (<Row>
            <Col>
                {printHumanRef()}
                <FormInputCheck
                    name={props.name}
                    type={props.type}
                    options={props.options}
                    readOnly={props.readOnly}

                    required={isRequired}
                    validator={props.validator}
                    blocker={props.blocker}
                    blockerMode={props.blockerMode}

                    runValidator={props.runValidator}
                    attributes={props.attributes}
                />
            </Col>
        </Row>)
    }

    if (!props.label) {
        return <Row><Col>
            {printHumanRef()}
            <FormInput
                name={props.name}
                type={props.type}
                options={props.options}
                readOnly={props.readOnly}

                required={isRequired}
                validator={props.validator}
                blocker={props.blocker}
                blockerMode={props.blockerMode}

                runValidator={props.runValidator}
                attributes={props.attributes}
            /></Col></Row>
    }

    return (
        <Row>
            <Col xs={4}>{printHumanRef()} {props.label} <FormHelp content={props.helpContent}/></Col>
            <Col><FormInput
                name={props.name}
                type={props.type}
                options={props.options}
                readOnly={props.readOnly}

                required={isRequired}
                validator={props.validator}
                blocker={props.blocker}

                runValidator={props.runValidator}
                attributes={props.attributes}
            /></Col>
        </Row>
    );
}

export default FormItem
