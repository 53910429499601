const changeFormValue = function (field: string, value: any, form: any, setValueHandler: any) {
    let keys = Object.keys(form)
    if (!keys.includes(field)) {
        form[field] = getDefaultFormAttribute()
    }

    // @ts-ignore
    form[field]['value'] = value

    form = validateForm(form);

    setValueHandler({...form})
}

const loadInput = function (form: any, props: any, setHandler: any) {
    let keys = Object.keys(form)

    if (!keys.includes(props.name)) {
        // @ts-ignore
        form[props.name] = getDefaultFormAttribute()
    }

    if (props.blocker) {
        // @ts-ignore
        form[props.name]['blocker'] = props.blocker;

        form[props.name]['blockerMode'] = props.blockerMode != null ? props.blockerMode : 1
    }

    if (props.validator) {
        // @ts-ignore
        form[props.name]['validator'] = props.validator;
    }

    if (props.required) {
        // @ts-ignore
        form[props.name]['required'] = props.required;
    }

    if (props.runValidator) {
        form = validateForm(form);
    }

    setHandler({...form});
}

const isEmpty = function (value: string | null) {
    return !value || value.length == 0;
}

const getFormAttribute = function (form: any, field: string, form_keys: null | string[] = null) {
    if (form_keys == null) {
        form_keys = Object.keys(form)
    }

    if (form_keys.includes(field)) {
        return form[field]
    }

    return getDefaultFormAttribute()
}

const getDefaultFormAttribute = () => {
    return {
        value: '',
        isInvalid: false,
        required: false,
    };
}

const validateForm = function (form: any) {
    let form_keys = Object.keys(form);

    console.log("validateForm");

    form_keys.forEach(function (field: string) {
        if (field.startsWith('_', 0)) {
            return;
        }

        form[field]['isBlocked'] = false;
        form[field]['isInvalid'] = false

        // Parent Assign
        if (form[field]['blocker']) {
            form[form[field]['blocker']]['parent'] = field;
        }

        // Validator
        if (form[field]['validator']) {
            if (!form[field]['validator'](form[field]['value'], form)) {
                form[field]['isInvalid'] = true
            }
        }

        // Blocking Check
        if (form[field]['parent'] && form[form[field]['parent']]['blockerMode'] == 1) {
            if (form[form[field]['parent']]['isBlocked']) {
                form[field]['isBlocked'] = true;
            } else {
                if (form[form[field]['parent']]['isInvalid']) {
                    form[field]['isBlocked'] = true;
                }
            }
        }

        // Empty Value
        if (isEmpty(form[field]['value'])) {

            if (form[field]['required']) {
                form[field]['isInvalid'] = true
            }

        }
    })

    /* let pasaport_no = getFormAttribute('form', 'basvuran_kisi_pasaport_no', form_keys)
    let kimlik_no = getFormAttribute('form', 'basvuran_kisi_kimlik_no', form_keys)
    if (isEmpty(pasaport_no['value']) && isEmpty(kimlik_no['value']) && form['basvuran_kisi_pasaport_no']) {
        form['basvuran_kisi_pasaport_no']['isInvalid'] = true
    } */

    return form
}

const exportForm = (form: any) => {
    let output = {}
    let output2 = {}

    let form_keys = Object.keys(form);
    form_keys.forEach(function (field: string) {
        if (field.startsWith('_', 0)) {
            // @ts-ignore
            output[field] = form[field]

            // @ts-ignore
            output2[field] = form[field]
        } else {
            // @ts-ignore
            output[field] = {'value': form[field]['value']}

            // @ts-ignore
            output2[field] = form[field]['value']
        }
    })

    return JSON.stringify({
        'raw': form,
        'fe': output,
        'be': output2,
    });
}

export {changeFormValue, validateForm, getFormAttribute, loadInput, exportForm}
