import React from 'react';
import FormItem from "../../form/form_item";
import {currencies} from "../../../settings";

function Receipt(props: {
    process_id: number,
    real_estate_id: number,
    receipt_id: number,
} = {
    process_id: 0,
    real_estate_id: 0,
    receipt_id: 0,
}) {
    const fieldPrefix = 'islem' + props.process_id + '_t' + props.real_estate_id + '_d' + props.receipt_id + '-'

    return (
        <>
            <br/>
            <h3>{props.process_id}. İşlem {'>'} {props.real_estate_id}. Taşınmaz {'>'} {props.receipt_id}. Dekont Bilgisi</h3>

            <FormItem type="date" label={"Tarih"} humanRef={"D10TR"} name={fieldPrefix + 'tarih'} blocker={fieldPrefix + 'tutar'}/>
            <FormItem type="number" attributes={{step: ".01"}} label={"Dekont Tutarı"} humanRef={"D10DT"} name={fieldPrefix + 'tutar'} blocker={fieldPrefix + 'birim'}/>
            <FormItem type="select" label={'Para Birimi'} name={fieldPrefix + 'birim'} humanRef={"D10BR"} blocker={fieldPrefix + 'miktar'} options={currencies}/>
            <FormItem type="number" attributes={{step: ".01"}} label={"Miktar"} humanRef={"D10MK"} name={fieldPrefix + 'miktar'} blocker={fieldPrefix + 'k1'}/>

            <FormItem type="switch" name={fieldPrefix + 'k1'} humanRef={"D10K1"} blocker={fieldPrefix + 'k2'} options={[
                {key: 1, value: "Bedel transferi/ödemenin ilgili kişiler arasında yapılıp yapılmadığı kontrol edildi mi?"},
            ]} validator={(value: string) => {
                return parseInt(value) === 1;
            }}/>

            <FormItem type="switch" name={fieldPrefix + 'k2'} humanRef={"D10K2"} options={[
                {key: 1, value: "Bedel transferi/ödemenin ilgili kişiler arasında yapılıp yapılmadığı kontrol edildi mi?"},
            ]} validator={(value: string) => {
                return parseInt(value) === 1;
            }} runValidator={true}/>
        </>
    );
}

export default Receipt;
