import React from "react";
import RealEstates from "../real_estates";
import FormItem from "../../form/form_item";
import {getUnixTimestamp} from "../../../utils/datetime";

function SalesContract(props: {
    process_id: number,
} = {
    process_id: 0,
}) {
    const fieldPrefix = 'islem' + props.process_id + '-'

    return (
        <>
            <br/>
            <FormItem type="date" label={"Satış Vaadi Sözleşmesi’nin tarihi"} humanRef={"SSTRH"} name={fieldPrefix + 'noterTarih'} blocker={fieldPrefix + 'noterYevmiyeNo'} validator={function (value: string, form_data: any) {
                if (getUnixTimestamp(value) < getUnixTimestamp('2018-12-07')) {
                    return false;
                }

                return getUnixTimestamp(form_data['tarih']['value']) <= getUnixTimestamp(value)
            }}/>
            <FormItem type="number" label={"Noterlik Yevmiye Numarası"} humanRef={"SSNYV"} name={fieldPrefix + 'noterYevmiyeNo'}/>

            <FormItem type="switch" name={fieldPrefix + 'k1'} humanRef={"SS0K1"} options={[
                {key: 1, value: "Satış Vaadine Konu Edilen Taşınmaz Kİ/KM’dir."},
            ]}/>

            <RealEstates process_id={props.process_id} for_contract={true}/>
            <hr/>
        </>
    );
}

export default SalesContract;
