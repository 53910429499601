import React from 'react';
import {useGlobalState} from "../../../App";
import _ from "lodash";
import {getFormAttribute} from "../../../utils/form";
import {Tab, Tabs} from "react-bootstrap";
import CurrencyExchange from "./currency_exchange";
import FormItem from 'components/form/form_item';


function CurrencyExchanges(props: {
    process_id: number,
    real_estate_id: number,
} = {
    process_id: 0,
    real_estate_id: 0
}) {
    const [form_data] = useGlobalState('form');

    const dovizAlimSayisiField = 'islem' + props.process_id + '_t' + props.real_estate_id + '-dovizAlimSayisi'

    return (
        <>
            <FormItem type="number" label={"Döviz alım belgesi sayısı"} humanRef={"DA00S"} name={dovizAlimSayisiField} attributes={{'max': 20}}/>

            <Tabs
                defaultActiveKey={'dbelge0'}
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                {_.times(getFormAttribute(form_data, dovizAlimSayisiField)['value'], (i: number) => (
                    <Tab key={i} eventKey={'dbelge' + i} title={(i + 1) + '. Döviz Alım Belgesi'}>
                        <CurrencyExchange key={i} process_id={props.process_id} real_estate_id={props.real_estate_id} curexc_id={i + 1}/>
                    </Tab>
                ))}
            </Tabs>
        </>
    );
}

export default CurrencyExchanges;
