import FormItem from 'components/form/form_item';
import React from 'react';
import {currencies} from "../../../settings";
import {getUnixTimestamp} from "../../../utils/datetime";

function CurrencyExchange(props: {
    process_id: number,
    real_estate_id: number,
    curexc_id: number,
} = {
    process_id: 0,
    real_estate_id: 0,
    curexc_id: 0,
}) {
    const fieldPrefix = 'islem' + props.process_id + '_t' + props.real_estate_id + '_da' + props.curexc_id + '-'

    return (
        <>
            <br/>
            <h3>{props.curexc_id}. Döviz Alım Belgesi</h3>

            <FormItem type="date" label={"Tarih"} humanRef={"DA10S"} name={fieldPrefix + 'tarih'} blocker={fieldPrefix + 'tutarTL'} validator={function (value: string, form_data: any) {
                return getUnixTimestamp(value) >= getUnixTimestamp(form_data['tarih']['value'])
            }}/>
            <FormItem type="number" attributes={{step: ".01"}} label={"TL Tutarı Giriniz"} humanRef={"DA1TL"} name={fieldPrefix + 'tutarTL'} blocker={fieldPrefix + 'birim'}/>
            <FormItem type="select" label={'Para Birimi'} humanRef={"DA1PB"} name={fieldPrefix + 'birim'} blocker={fieldPrefix + 'miktar'} options={currencies}/>
            <FormItem type="number" attributes={{step: ".01"}} label={"Miktar"} humanRef={"DA1MK"} name={fieldPrefix + 'miktar'} blocker={fieldPrefix + 'kur'}/>
            <FormItem type="number" attributes={{step: ".01"}} label={"Kur"} humanRef={"DA1KR"} name={fieldPrefix + 'kur'} blocker={fieldPrefix + 'k1'}/>

            <FormItem type="switch" humanRef={"DA1K1"} name={fieldPrefix + 'k1'} blocker={fieldPrefix + 'k2'} options={[
                {key: 1, value: "Döviz alım belgesinin tarafları alıcı satıcı, vekili veya temsilcisi mi?"},
            ]} validator={(value: string) => {
                return parseInt(value) === 1;
            }}/>

            <FormItem type="switch" humanRef={"DA1K2"} name={fieldPrefix + 'k2'} options={[
                {key: 1, value: "Adına Döviz bozdurulan kişinin Adı Soyadı pasaport numarası ya da yabancı kimlik numarası taşınmaz numarası ve alışı yapılan dövizin bu işlem için kullanılıp kullanılmadığı kontrol edilip, onaylanmıştır."},
            ]} validator={(value: string) => {
                return parseInt(value) === 1;
            }} runValidator={true}/>
        </>
    );
}

export default CurrencyExchange;
