import React, {useEffect} from 'react';
import {Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import {createGlobalState} from "react-hooks-global-state";
import {form} from "./settings";
import {exportForm, validateForm} from "./utils/form";
import FirstStep from "./components/steps/first_step";
import SecondStep from "./components/steps/second_step";
import ThirdStep from "./components/steps/third_step";
import ProcessStep from "./components/steps/step_process";

const initialState = {
    form: form
};
console.log("Initial Form State", initialState.form);

const {useGlobalState} = createGlobalState(initialState);

function App() {
    const [form_data, setForm] = useGlobalState('form');

    useEffect(() => {
        let filtered_form = validateForm(form_data);
        setForm({...filtered_form})
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={10}>

                    <Tabs
                        defaultActiveKey={'first'}
                        id="main-tab"
                        className="mb-3"
                    >
                        <Tab eventKey={'first'} title={'1. Adım'}>
                            <FirstStep/>
                        </Tab>
                        <Tab eventKey={'second'} title={'2. Adım'}>
                            <SecondStep/>
                        </Tab>
                        <Tab eventKey={'third'} title={'Başvuran Bilgileri'}>
                            <ThirdStep/>
                        </Tab>
                        <Tab eventKey={'fourth'} title={'Tapu İşlemleri'}>
                            <ProcessStep/>
                        </Tab>
                    </Tabs>

                </Col>
                <Col xs={2}>{exportForm(form_data)}</Col>
            </Row>
        </Container>
    );
}

export default App;
export {useGlobalState}
