import React from 'react';
import FormItem from "../form/form_item";
import {useGlobalState} from "../../App";
import {getFormAttribute} from "../../utils/form";
import _ from "lodash";
import {Tab, Tabs} from 'react-bootstrap';
import ValuationReport from "./valuation_report";
import Receipts from "./receipt/receipts";
import Sellers from "./seller/sellers";
import CurrencyExchanges from "./currency_exchange/currency_exchanges";

function RealEstates(props: {
    process_id: number,
    for_contract?: boolean,
} = {
    process_id: 0,
    for_contract: false
}) {
    const [form_data] = useGlobalState('form');

    const fieldPrefix = 'islem' + props.process_id + '_t'

    const tasinmazSayisiField = 'islem' + props.process_id + '-tasinmazSayisi'
    const tasinmaz_sayisi = getFormAttribute(form_data, tasinmazSayisiField);

    const currencyExchanges = (i: number) => {
        let real_estate_id = i + 1;
        if (real_estate_id > 1) {
            let dovizAlimVar = getFormAttribute(form_data, fieldPrefix + real_estate_id + '-dovizAlimVar')

            return <><FormItem type="switch" humanRef={"DA00V"} name={fieldPrefix + real_estate_id + '-dovizAlimVar'} options={[
                {key: 1, value: "Bu taşınmaz için döviz alım belgesi vardır."},
            ]} required={false}/>
                {dovizAlimVar['value'] == 1 && <CurrencyExchanges process_id={props.process_id} real_estate_id={i + 1}/>}
            </>
        } else {
            return <CurrencyExchanges process_id={props.process_id} real_estate_id={i + 1}/>
        }
    }

    return (
        <>
            <br/>
            <FormItem type="number" label={"İşleme dahil edilen taşınmaz adedi"} humanRef={"T000S"} name={tasinmazSayisiField} attributes={{'max': 20}} runValidator={true}/>

            {tasinmaz_sayisi['value'] &&
                <Tabs
                    defaultActiveKey={'islem' + props.process_id + '_tasinmaz0'}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    {_.times(tasinmaz_sayisi['value'], (i: number) => (
                        <Tab key={i} eventKey={'islem' + props.process_id + '_tasinmaz' + i} title={(i + 1) + '. Taşınmaz'}>
                            <FormItem type="number" label={"Taşınmaz Sistem Numarası"} humanRef={"T00NO"} name={fieldPrefix + (i + 1) + '-no'}/>

                            <FormItem type="number" attributes={{step: ".01"}} label={!props.for_contract ? "Toplam Satış Bedeli" : "Satış Vaadine Konu Bedel"} humanRef={"T00SB"} name={fieldPrefix + (i + 1) + '-bedel'}/>
                            <strong>Resmi Senet üzerinden bakılacak</strong><br/><br/>

                            {props.for_contract && <FormItem type="switch" humanRef={"T00K1"} name={fieldPrefix + (i + 1) + '-k1'} options={[
                                {key: 1, value: "Yukarıda girilen bedel, satış vaadi sözleşmesi kontrol edilerek peşin ödenen kısım içindir."},
                            ]} validator={(value: string) => {
                                return parseInt(value) === 1;
                            }}/>}

                            <Tabs
                                defaultActiveKey={'sellers' + props.process_id + '_re' + i}
                                id={'islem' + props.process_id + '_tasinmaz' + i + 'TAB'}
                                className="mb-3"
                            >
                                <Tab eventKey={'sellers' + props.process_id + '_re' + i} title={'Satıcılar'}>
                                    <Sellers process_id={props.process_id} real_estate_id={i + 1}/>
                                </Tab>
                                <Tab eventKey={'receipts' + props.process_id + '_re' + i} title={'Dekontlar'}>
                                    <Receipts process_id={props.process_id} real_estate_id={i + 1}/>
                                </Tab>
                                <Tab eventKey={'valuationReport' + props.process_id + '_re' + i} title={'Değerleme Raporu'}>
                                    <ValuationReport process_id={props.process_id} real_estate_id={i + 1}/>
                                </Tab>
                                <Tab eventKey={'currencyExchange' + props.process_id + '_re' + i} title={'Döviz Alım Belgesi'}>
                                    {currencyExchanges(i)}
                                </Tab>
                            </Tabs>
                        </Tab>
                    ))}
                </Tabs>
            }
        </>
    );
}

export default RealEstates;
