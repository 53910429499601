import React from 'react';
import FormItem from "../form/form_item";
import SalesContract from "./types/sales_contract";
import {getFormAttribute} from "../../utils/form";
import {useGlobalState} from "../../App";
import {mudurlukler} from "../../settings";
import RealEstates from "./real_estates";

function Process(props: {
    process_id: number
} = {
    process_id: 0
}) {
    const [form_data] = useGlobalState('form');

    const fieldPrefix = 'islem' + props.process_id + '-'

    const tur_field = fieldPrefix + 'tur'
    const islem_turu = getFormAttribute(form_data, tur_field);

    return (
        <>
            <h2>{props.process_id}. İşlem</h2>
            <hr/>

            <FormItem type="select" label={"Edinim Türü"} humanRef={"I00ET"} name={tur_field} blocker={fieldPrefix + 'tarih'} options={[
                {key: 1, value: "Satış"},
                {key: 2, value: "Satış Vaadi Sözleşmesi"},
            ]}/>

            <br/>
            <FormItem type="date" label={"İşlem Tarihi"} humanRef={"I00IT"} name={fieldPrefix + 'tarih'} blocker={fieldPrefix + 'yevmiyeNo'}/>
            <FormItem type="number" label={"Yevmiye Numarası"} humanRef={"I00YN"} name={fieldPrefix + 'yevmiyeNo'} blocker={fieldPrefix + 'mudurluk'}/>
            <FormItem type="select" label={"İşlemi Yapan Tapu Müdürlüğü"} humanRef={"I0IYT"} name={fieldPrefix + 'mudurluk'} options={mudurlukler} runValidator={true}/>

            {islem_turu['value'] === "1" &&
                <RealEstates process_id={props.process_id}/>
            }
            {islem_turu['value'] === "2" &&
                <SalesContract process_id={props.process_id}/>
            }
        </>
    );
}

export default Process;
