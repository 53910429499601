import React from 'react';
import FormItem from "../form/form_item";
import {useGlobalState} from "../../App";
import {getFormAttribute} from "../../utils/form";

function ThirdStep() {
    const [form_data] = useGlobalState('form');

    const fieldPrefix = 'basvuran-'

    const pasaportUlkeField = fieldPrefix + 'pasaportUlke';
    const uyrukField = fieldPrefix + 'uyruk';

    const ulke = getFormAttribute(form_data, pasaportUlkeField);
    const uyruk = getFormAttribute(form_data, uyrukField);

    return (
        <>
            <p>Vatandaşlık edinimine konu yabancı kişi ve işlemin taraf bilgileri ekranı.</p>

            <FormItem type="text" label={"Adı"} humanRef={"A30AD"} name={fieldPrefix + 'adi'} blocker={fieldPrefix + 'soyadi'}/>
            <FormItem type="text" label={"Soyadı"} humanRef={"A30SY"} name={fieldPrefix + 'soyadi'} blocker={fieldPrefix + 'adres'}/>
            <FormItem type="text" label={"Adresi"} humanRef={"A30AR"} name={fieldPrefix + 'adres'} blocker={fieldPrefix + 'telefon'}/>
            <FormItem type="text" label={"Telefon Numarası"} humanRef={"A30TN"} name={fieldPrefix + 'telefon'} blocker={fieldPrefix + 'eposta'}/>
            <FormItem type="text" label={"E-Posta Adresi"} humanRef={"A30EP"} name={fieldPrefix + 'eposta'} blocker={fieldPrefix + 'kimlikNo'}/>
            <FormItem type="text" label={"Yabancı Kimlik No"} humanRef={"A3YKN"} name={fieldPrefix + 'kimlikNo'} blocker={fieldPrefix + 'pasaportNo'}/>
            <FormItem type="text" label={"Pasaport No"} humanRef={"A30PN"} name={fieldPrefix + 'pasaportNo'} blocker={pasaportUlkeField}/>
            <FormItem type="text" label={"Verilen Ülke (Country Code)"} humanRef={"A30VU"} name={pasaportUlkeField} blocker={fieldPrefix + 'cifteUyrukKontrol'}/>
            <FormItem type="switch" humanRef={"A30K1"} name={fieldPrefix + 'cifteUyrukKontrol'} blocker={uyrukField} blockerMode={2} options={[
                {key: 1, value: "Çifte uyrukluk kontrolü yapıldı. Edinimi engelleyen çifte uyrukluğa rastlanılmadı."},
            ]}/>

            <FormItem type="text" label={"Uyruğu (Nationality)"} humanRef={"A30UY"} name={uyrukField} blocker={'islemSayisi'}/>

            {ulke['value'] && uyruk['value'] && ulke['value'] != uyruk['value'] && <FormItem type="switch" humanRef={"A30K2"} name={fieldPrefix + 'uyrukPasaportKontrol'} options={[
                {key: 1, value: "Gerekli belgelerin doğruluğu kontrol edildi."},
            ]}/>}
        </>
    );
}

export default ThirdStep;
