import React from 'react';
import FormItem from "../form/form_item";
import {useGlobalState} from "../../App";
import {getFormAttribute} from "../../utils/form";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import _ from "lodash";
import Process from "../sections/process";


function ProcessStep() {
    const [form_data] = useGlobalState('form');

    const islem_sayisi = getFormAttribute(form_data, 'islemSayisi')['value']

    return (
        <>
            <FormItem type="number" label={"Bu yabancı 400.000$ >=’ı kaç farklı tapu işlemi ile tamamlamıştır."} humanRef={"IS"} name="islemSayisi" attributes={{'max': 20}}/>
            <br/>

            <Tab.Container id="main-tabs" defaultActiveKey="process-0">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            {_.times(islem_sayisi, (i: number) => (
                                <Nav.Item key={i}>
                                    <Nav.Link eventKey={"process-" + i}>{i + 1}. İşlem</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            {_.times(islem_sayisi, (i: number) => (
                                <Tab.Pane key={i} eventKey={"process-" + i}>
                                    <Process process_id={i + 1}/>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
}

export default ProcessStep;
