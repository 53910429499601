import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
import {useGlobalState} from "../../../App";
import {changeFormValue, getFormAttribute, loadInput} from "../../../utils/form";

export function FormInputSelect(props: {
    name: string,
    options: any[],

    readOnly?: boolean,
    required?: null | boolean,
    validator?: any,
    blocker?: null | string,
    blockerMode?: null | number,
    runValidator?: boolean,
    attributes?: any,
} = {
    name: '',
    options: [],

    readOnly: false,
    required: null,
    validator: null,
    blocker: null,
    blockerMode: null,
    runValidator: false,
    attributes: null,
}) {
    const [form, setForm] = useGlobalState('form');

    const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        changeFormValue(props.name, e.target.value, form, setForm);
    };

    useEffect(() => {
        loadInput(form, props, setForm)
    }, []);

    const field = getFormAttribute(form, props.name);

    return (
        // @ts-ignore
        <Form.Select
            value={field['value']}

            onChange={onChangeHandler}
            isInvalid={field['isInvalid']}
            required={field['required'] || props.required}

            {...props.attributes}

            disabled={field['isBlocked'] || props.readOnly}
        >
            <option key="blank" value=''>Seçiniz</option>
            {props.options.map(function (object, i) {
                return <option key={i} value={object.key}>{object.value}</option>
            })}
        </Form.Select>);
}
