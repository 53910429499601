import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
import {useGlobalState} from "../../../App";
import {changeFormValue, getFormAttribute, loadInput} from "../../../utils/form";

export function FormInputText(props: {
    name: string,
    readOnly?: boolean,

    required?: null | boolean,
    validator?: any,
    blocker?: null | string,
    blockerMode?: null | number,
    runValidator?: boolean,
    attributes?: any,
} = {
    name: '',
    readOnly: false,

    required: null,
    validator: null,
    blocker: null,
    blockerMode: null,
    runValidator: false,
    attributes: null,
}) {
    const [form, setForm] = useGlobalState('form');

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeFormValue(props.name, e.target.value, form, setForm)
    };

    useEffect(() => {
        loadInput(form, props, setForm)
    }, []);

    const field = getFormAttribute(form, props.name);

    // @ts-ignore
    return (<Form.Control
        type="text"
        value={field['value']}

        onChange={onChangeHandler}
        isInvalid={field['isInvalid']}
        required={field['required'] || props.required}

        {...props.attributes}

        readOnly={props.readOnly}
        plaintext={props.readOnly}
        disabled={field['isBlocked']}
    />);
}
