import React from 'react';
import {useGlobalState} from "../../../App";
import _ from "lodash";
import {getFormAttribute} from "../../../utils/form";
import Seller from "./seller";
import {Tab, Tabs} from "react-bootstrap";
import FormItem from 'components/form/form_item';


function Sellers(props: {
    process_id: number,
    real_estate_id: number,
} = {
    process_id: 0,
    real_estate_id: 0
}) {
    const [form_data] = useGlobalState('form');

    const field = 'islem' + props.process_id + '_t' + props.real_estate_id + '-saticiSayisi'
    const satici_sayisi = getFormAttribute(form_data, field)

    return (
        <>
            <FormItem type="number" label={"Satıcı Sayısı"} humanRef={"S000S"} name={field} attributes={{'max': 20}}/>
            <br/>

            {satici_sayisi['value'] &&
                <Tabs
                    defaultActiveKey={'islem' + props.process_id + '_tasinmaz' + props.real_estate_id + '_satici0'}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    {_.times(satici_sayisi['value'], (i: number) => (
                        <Tab key={i} eventKey={'islem' + props.process_id + '_tasinmaz' + props.real_estate_id + '_satici' + i} title={(i + 1) + '. Satıcı'}>
                            <Seller key={i} process_id={props.process_id} seller_id={i + 1} real_estate_id={props.real_estate_id}/>
                        </Tab>
                    ))}
                </Tabs>}
        </>
    );
}

export default Sellers;
