import FormItem from 'components/form/form_item';
import React from 'react';
import {useGlobalState} from "../../App";
import {mudurlukler} from "../../settings";
import {getFormAttribute} from "../../utils/form";

function SecondStep() {
    const [form_data] = useGlobalState('form');

    const fieldPrefix = 'taahhut-'

    const getMudurluk = (id: number) => {
        let mudurluk = mudurlukler.filter((mudurluk) => {
            return mudurluk['key'] == id
        });

        if (mudurluk.length == 0) {
            return '-';
        }

        return mudurluk[0]['value']
    }

    return (
        <>
            <strong>{getMudurluk(getFormAttribute(form_data, 'mudurluk')['value'])} TAPU MÜDÜRLÜĞÜ</strong><br/><br/>

            <p>Türk Vatandaşlığı Kanunu Uygulamasına İlişkin Yönetmelik Kapsamında 3 yıl içerisinde satılamayacağına ilişkin taahhüt belirtmesi bilgileri</p>

            <FormItem type="date" label={"İŞLEMİN TARİHİ"} humanRef={"A20IT"} name={fieldPrefix + 'tarih'} blocker={fieldPrefix + 'yevmiyeNo'}/>
            <FormItem type="number" label={"İŞLEMİN YEVMİYE NOSU"} humanRef={"A2IYN"} name={fieldPrefix + 'yevmiyeNo'} blocker={fieldPrefix + 'vekalet'}/>

            <FormItem type="radio" humanRef={"A2K01"} name={fieldPrefix + 'vekalet'} blocker={fieldPrefix + 'yevmiyeTarih'} blockerMode={2} options={[
                {key: 1, value: "İşlem vekaletsiz."},
                {key: 2, value: "İşlem vekaletliyse vekaletnamede taahhüde ilişkin yetki var."},
                {key: 3, value: "İşlem vekaletliyse vekaletnamede taahhüde ilişkin yetki yok."},
            ]} validator={(value: string) => {
                return parseInt(value) !== 3
            }}/>

            <FormItem type="radio" humanRef={"A2K02"} name={fieldPrefix + 'yevmiyeTarih'} blocker={'basvuran-adi'} options={[
                {key: 1, value: "Yabancı vatandaşlık edinimine ilişkin taahhüt tek tarihli yevmiye ile yapılmamış."},
                {key: 2, value: "Yabancı vatandaşlık edinimine ilişkin taahhüt tek tarihli yevmiye ile yapılmış."},
            ]} validator={(value: string) => {
                return parseInt(value) === 2
            }}/>
        </>
    );
}

export default SecondStep;
