import React from 'react';
import FormItem from "../../form/form_item";
import {useGlobalState} from "../../../App";
import {getFormAttribute} from "../../../utils/form";

function Seller(props: {
    process_id: number,
    real_estate_id: number,
    seller_id: number,
} = {
    process_id: 0,
    real_estate_id: 0,
    seller_id: 0,
}) {
    const [form_data] = useGlobalState('form');

    const fieldPrefix = 'islem' + props.process_id + '_t' + props.real_estate_id + '_s' + props.seller_id + '-'

    const turField = fieldPrefix + 'tur'
    const tur = getFormAttribute(form_data, turField)

    return (
        <>
            <br/>
            <h3>{props.process_id}. İşlem {'>'} {props.real_estate_id}. Taşınmaz {'>'} {props.seller_id}. Satıcı Bilgisi</h3>

            <FormItem type="text" label={"Adı Soyadı / Ünvanı"} humanRef={"S1ADU"} name={fieldPrefix + 'ad'} blocker={fieldPrefix + 'tur'}/>
            <FormItem type="select" label={"Kişilik Türü"} humanRef={"S10KT"} name={turField} options={[
                {key: 1, value: "Gerçek Kişi"},
                {key: 2, value: "Tüzel Kişi"},
            ]} blocker={fieldPrefix + 'uyruk'}/>
            <FormItem type="text" label={"Uyruğu (Nationality)"} humanRef={"S10UY"} name={fieldPrefix + 'uyruk'} blocker={fieldPrefix + 'hisse'}/>
            <FormItem type="number" attributes={{step: ".01"}} label={"Hisse (TL)"} humanRef={"S10HS"} name={fieldPrefix + 'hisse'} blocker={fieldPrefix + 'k1'}/>

            <FormItem type="radio" humanRef={"S10K1"} name={fieldPrefix + 'k1'} blocker={fieldPrefix + 'k2'} options={[
                {key: 1, value: "İşlemi konu taşınmaz/taşınmazlardan birisi/hisesi daha önce Türk Vatandaşlığı’na konu edilmiş.(Vatandaşlık taahhüdü pasif olsa bile)"},
                {key: 2, value: "İşlemi konu taşınmaz/taşınmazlardan birisi/hisesi daha önce Türk Vatandaşlığı’na konu edilmemiş"},
            ]} validator={(value: string) => {
                return parseInt(value) === 2;
            }}/>

            <FormItem type="radio" humanRef={"S10K2"} name={fieldPrefix + 'k2'} blocker={fieldPrefix + 'k3'} options={[
                {key: 1, value: "İşlemde yapısız taşınmaz var, proje taahhüdü alınmamış."},
                {key: 2, value: "İşlemde yapısız taşınmaz yok."},
            ]} validator={(value: string) => {
                return parseInt(value) === 2;
            }}/>

            <FormItem type="radio" humanRef={"S10K3"} name={fieldPrefix + 'k3'} blocker={fieldPrefix + 'k4'} options={[
                {key: 1, value: "İşlemde T.C. Vatandaşlığı Edinimi'ni olumsuz etkileyecek ipotek vardır."},
                {key: 2, value: "İşlemde ipotek vardır, ancak yapılan incelemeler neticesinde T.C. Vatandaşlığı Edinimi'ni olumsuz etkilememektedir."},
                {key: 3, value: "İşlemde İpotek yoktur."},
            ]} validator={(value: string) => {
                return parseInt(value) !== 1;
            }}/>

            <FormItem type="radio" humanRef={"S10K4"} name={fieldPrefix + 'k4'} blocker={fieldPrefix + 'k5'} options={[
                {key: 1, value: "İşlemde yabancılar arası edinim var."},
                {key: 2, value: "İşlemde yabancılar arası edinim yok."},
            ]} validator={(value: string) => {
                return parseInt(value) === 2;
            }}/>

            <FormItem type="radio" humanRef={"S10K5"} name={fieldPrefix + 'k5'} blocker={fieldPrefix + 'k6'} options={[
                {key: 1, value: "İşleme konu taşınmazların pasif kaydında 12.01.2017 tarihinden sonra aynı uyruklu kişi vardır."},
                {key: 2, value: "İşleme konu taşınmazların pasif kaydında 12.01.2017 tarihinden sonra aynı uyruklu kişi yoktur."},
            ]} validator={(value: string) => {
                return parseInt(value) === 2;
            }}/>

            <FormItem type="radio" humanRef={"S10K6"} name={fieldPrefix + 'k6'} blocker={fieldPrefix + 'k7'} options={[
                {key: 1, value: "İşleme konu taşınmaz/taşınmazlar kendisi, eşi ve çocukları tarafından 12.01.2017 tarihinden sonra devredilmiş taşınmazdır."},
                {key: 2, value: "İşleme konu taşınmaz/taşınmazlar kendisi, eşi ve çocukları tarafından 12.01.2017 tarihinden sonra devredilmiş taşınmaz değildir."},
            ]} validator={(value: string) => {
                return parseInt(value) === 2;
            }}/>

            <FormItem type="radio" humanRef={"S10K7"} name={fieldPrefix + 'k7'} blocker={fieldPrefix + 'k8'} options={[
                {key: 1, value: "Satıcı son 3 yıl içerisinde taşınmaz edinmek suretiyle vatandaş olmuştur."},
                {key: 2, value: "Satıcı son 3 yıl içerisinde taşınmaz edinmek suretiyle vatandaş olmamıştır."},
            ]} validator={(value: string) => {
                return parseInt(value) === 2;
            }} runValidator={true}/>

            {tur['value'] == 2 && <>
                <FormItem type="radio" humanRef={"S10K8"} name={fieldPrefix + 'k8'} blocker={fieldPrefix + 'k9'} options={[
                    {key: 1, value: "Satıcı şirket ise şirket ortaklarından veya yöneticisi, alıcının kendisi, eşi veya çocuğudur."},
                    {key: 2, value: "Satıcı şirket değildir veya şirket ortaklarından/yöneticisi alıcının kendisi eşi veya çocuğu değildir."},
                ]} validator={(value: string) => {
                    return parseInt(value) === 2;
                }}/>

                <FormItem type="radio" humanRef={"S10K9"} name={fieldPrefix + 'k9'} options={[
                    {key: 1, value: "Satıcı şirket ise ve bu şirket Tapu Kanunu’nun 36. Maddesi kapsamında ise şirketin uyruğu ile alıcının uyruğu aynıdır."},
                    {key: 2, value: "Satıcı şirket değildir/Tapu Kanunu’nun 36. Maddesi kapsamındaki şirket değildir veya uyrukları farklıdır."},
                ]} validator={(value: string) => {
                    return parseInt(value) === 2
                }} runValidator={true}/>
            </>
            }
        </>
    );
}

export default Seller;
