const mudurlukler: { key: number, value: string }[] = [
    {key: 1, value: "Ankara"},
    {key: 2, value: "İstanbul"},
    {key: 3, value: "İzmir"},
]

const currencies = [
    {key: 'TRY', value: "Türk Lirası"},
    {key: 'USD', value: 'Amerikan Doları'},
];

const form = {
    "_id": null,
    "mudurluk": {
        "value": "1"
    },
    "tarih": {
        "value": "2022-09-08"
    },
    "sayi": {
        "value": "ABC123"
    },
    "taahhut-tarih": {
        "value": "2022-09-07"
    },
    "taahhut-yevmiyeNo": {
        "value": "123"
    },
    "taahhut-vekalet": {
        "value": "1"
    },
    "taahhut-yevmiyeTarih": {
        "value": "2"
    },
    "basvuran-adi": {
        "value": "a"
    },
    "basvuran-soyadi": {
        "value": "b"
    },
    "basvuran-adres": {
        "value": "c"
    },
    "basvuran-telefon": {
        "value": "d"
    },
    "basvuran-eposta": {
        "value": "e"
    },
    "basvuran-kimlikNo": {
        "value": "f"
    },
    "basvuran-pasaportNo": {
        "value": "g"
    },
    "basvuran-pasaportUlke": {
        "value": "h"
    },
    "basvuran-cifteUyrukKontrol": {
        "value": "1"
    },
    "basvuran-uyruk": {
        "value": "d"
    },
    "islemSayisi": {
        "value": "1"
    },
    "basvuran-uyrukPasaportKontrol": {
        "value": "1"
    },
    "islem1-tur": {
        "value": "1"
    },
    "islem1-tarih": {
        "value": "2022-09-11"
    },
    "islem1-yevmiyeNo": {
        "value": "1"
    },
    "islem1-mudurluk": {
        "value": "1"
    },
    "islem1-tasinmazSayisi": {
        "value": "2"
    },
    "islem1_t1-no": {
        "value": "1"
    },
    "islem1_t1-bedel": {
        "value": "100"
    },
    "islem1_t1-saticiSayisi": {
        "value": "2"
    },
    "islem1_t1-dekontSayisi": {
        "value": "1"
    },
    "islem1_t1_deg-tarih": {
        "value": "2022-09-10"
    },
    "islem1_t1_deg-degerTL": {
        "value": "1"
    },
    "islem1_t1_deg-degerUSD": {
        "value": "2"
    },
    "islem1_t1_deg-durum": {
        "value": "3"
    },
    "islem1_t1_deg-k1": {
        "value": "1"
    },
    "islem1_t1_deg-k2": {
        "value": "1"
    },
    "islem1_t1_deg-k3": {
        "value": "1"
    },
    "islem1_t1_deg-k4": {
        "value": "1"
    },
    "islem1_t1_deg-k5": {
        "value": "1"
    },
    "islem1_t1_deg-k6": {
        "value": "1"
    },
    "islem1_t1_deg-k7": {
        "value": "1"
    },
    "islem1_t1_deg-k8": {
        "value": "1"
    },
    "islem1_t1_deg-k9": {
        "value": "1"
    },
    "islem1_t1_deg-k10": {
        "value": "1"
    },
    "islem1_t2-no": {
        "value": "123"
    },
    "islem1_t2-bedel": {
        "value": "123"
    },
    "islem1_t2-saticiSayisi": {
        "value": "1"
    },
    "islem1_t2-dekontSayisi": {
        "value": "1"
    },
    "islem1_t2_deg-tarih": {
        "value": "2022-09-02"
    },
    "islem1_t2_deg-degerTL": {
        "value": "1"
    },
    "islem1_t2_deg-degerUSD": {
        "value": "2"
    },
    "islem1_t2_deg-durum": {
        "value": "1"
    },
    "islem1_t2_deg-k1": {
        "value": "1"
    },
    "islem1_t2_deg-k2": {
        "value": "1"
    },
    "islem1_t2_deg-k3": {
        "value": "1"
    },
    "islem1_t2_deg-k4": {
        "value": "1"
    },
    "islem1_t2_deg-k5": {
        "value": "1"
    },
    "islem1_t2_deg-k6": {
        "value": "1"
    },
    "islem1_t2_deg-k7": {
        "value": "1"
    },
    "islem1_t2_deg-k8": {
        "value": "1"
    },
    "islem1_t2_deg-k9": {
        "value": "1"
    },
    "islem1_t2_deg-k10": {
        "value": "1"
    },
    "islem1_t1_s1-ad": {
        "value": "a"
    },
    "islem1_t1_s1-tur": {
        "value": "1"
    },
    "islem1_t1_s1-uyruk": {
        "value": "a"
    },
    "islem1_t1_s1-hisse": {
        "value": "1"
    },
    "islem1_t1_s1-k1": {
        "value": "2"
    },
    "islem1_t1_s1-k2": {
        "value": "2"
    },
    "islem1_t1_s1-k3": {
        "value": "3"
    },
    "islem1_t1_s1-k4": {
        "value": "3"
    },
    "islem1_t1_s1-k5": {
        "value": "2"
    },
    "islem1_t1_s1-k6": {
        "value": "2"
    },
    "islem1_t1_s1-k7": {
        "value": "2"
    },
    "islem1_t1_s1-k8": {
        "value": "2"
    },
    "islem1_t1_s2-ad": {
        "value": "b"
    },
    "islem1_t1_s2-tur": {
        "value": "2"
    },
    "islem1_t1_s2-uyruk": {
        "value": "c"
    },
    "islem1_t1_s2-hisse": {
        "value": "2"
    },
    "islem1_t1_s2-k1": {
        "value": "2"
    },
    "islem1_t1_s2-k2": {
        "value": "2"
    },
    "islem1_t1_s2-k3": {
        "value": "2"
    },
    "islem1_t1_s2-k4": {
        "value": "3"
    },
    "islem1_t1_s2-k5": {
        "value": "2"
    },
    "islem1_t1_s2-k6": {
        "value": "2"
    },
    "islem1_t1_s2-k7": {
        "value": "2"
    },
    "islem1_t1_s2-k8": {
        "value": "2"
    },
    "islem1_t1_d1-tarih": {
        "value": "2022-09-09"
    },
    "islem1_t1_d1-tutar": {
        "value": "1"
    },
    "islem1_t1_d1-birim": {
        "value": "TRY"
    },
    "islem1_t1_d1-miktar": {
        "value": "12"
    },
    "islem1_t1_d1-k1": {
        "value": "1"
    },
    "islem1_t1_d1-k2": {
        "value": "1"
    },
    "islem1_t1_s1-k9": {
        "value": ""
    },
    "islem1_t1_s1-k10": {
        "value": ""
    },
    "islem1_t1_s2-k9": {
        "value": "2"
    },
    "islem1_t1_s2-k10": {
        "value": "2"
    },
    "islem1_t2_s1-ad": {
        "value": "a"
    },
    "islem1_t2_s1-tur": {
        "value": "1"
    },
    "islem1_t2_s1-uyruk": {
        "value": "a"
    },
    "islem1_t2_s1-hisse": {
        "value": "1233"
    },
    "islem1_t2_s1-k1": {
        "value": "2"
    },
    "islem1_t2_s1-k2": {
        "value": "2"
    },
    "islem1_t2_s1-k3": {
        "value": "2"
    },
    "islem1_t2_s1-k4": {
        "value": "2"
    },
    "islem1_t2_s1-k5": {
        "value": "2"
    },
    "islem1_t2_s1-k6": {
        "value": "2"
    },
    "islem1_t2_s1-k7": {
        "value": "2"
    },
    "islem1_t2_s1-k8": {
        "value": "2"
    },
    "islem1_t2_d1-tarih": {
        "value": "2022-09-01"
    },
    "islem1_t2_d1-tutar": {
        "value": "1"
    },
    "islem1_t2_d1-birim": {
        "value": "TRY"
    },
    "islem1_t2_d1-miktar": {
        "value": "123"
    },
    "islem1_t2_d1-k1": {
        "value": "1"
    },
    "islem1_t2_d1-k2": {
        "value": "1"
    },
    "islem1_t1_deg-aciklama": {
        "value": "açıklamaa"
    },
    "islem1_t1-dovizAlimSayisi": {
        "value": "1"
    },
    "islem1_t2-dovizAlimSayisi": {
        "value": "1"
    },
    "islem1_t1_da1-tarih": {
        "value": "2022-09-08"
    },
    "islem1_t1_da1-tutarTL": {
        "value": "1"
    },
    "islem1_t1_da1-birim": {
        "value": "TRY"
    },
    "islem1_t1_da1-miktar": {
        "value": "1"
    },
    "islem1_t1_da1-kur": {
        "value": "1.98"
    },
    "islem1_t1_da1-k1": {
        "value": "1"
    },
    "islem1_t1_da1-k2": {
        "value": "1"
    },
    "islem1_t2-dovizAlimVar": {
        "value": "1"
    },
    "islem1_t2_da1-tarih": {
        "value": "2022-09-10"
    },
    "islem1_t2_da1-tutarTL": {
        "value": "1"
    },
    "islem1_t2_da1-birim": {
        "value": "TRY"
    },
    "islem1_t2_da1-miktar": {
        "value": "1"
    },
    "islem1_t2_da1-kur": {
        "value": "2"
    },
    "islem1_t2_da1-k1": {
        "value": "1"
    },
    "islem1_t2_da1-k2": {
        "value": "1"
    }
}

export {mudurlukler, currencies, form}
