import React from "react";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";

export function FormHelp(props: {
    content?: string | null
} = {
    content: ''
}) {
    if (!props.content) {
        return null;
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Yardım</Popover.Header>
            <Popover.Body>{props.content}</Popover.Body>
        </Popover>
    );

    return (<OverlayTrigger trigger="click" placement="right" overlay={popover}>
        <Button variant="info">?</Button>
    </OverlayTrigger>);
}
